import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const NewsCard = ({ newsTitle, newsDate, newsImage, newsId }) => {

    const date = new Date(newsDate).toLocaleDateString()

    return (
        <div className='news-col'>
            <Link to={`/news/${newsId}`}><img src={newsImage} alt="" /></Link>
            <Link to={`/news/${newsId}`}><p>{newsTitle}</p></Link>
            <Link to={`/news/${newsId}`}><p className='news-date'>{date}</p></Link>
            <Link to={`/news/${newsId}`} className="btn01">READ NOW</Link>
        </div>
    )
}

NewsCard.defaultProps = {
    newsTitle: 'No Title' ,
    newsDate: 'No Date' ,
    newsImage: 'https://jmva.or.jp/wp-content/uploads/2018/07/noimage.png'
}

NewsCard.propTypes = {
    newsTitle: PropTypes.string.isRequired,
}

export default NewsCard

import heroVideo from '../assets/hero-video.mp4'
import heroLogo from '../assets/mnnf_logo_white_hero.png'

const Hero = () => {
    return (
        <section className="hero">
            <video autoPlay loop muted src={heroVideo} playsInline className="hero-video"></video>
            <div className="hero-content">
                <img src={heroLogo} alt='hero-logo' className='hero-logo' />
            </div>
        </section> 
    )
}

export default Hero

import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_DATA_QUERY } from '../graphql/Queries'
import Hero from '../components/Hero'
import NewsCard from '../components/NewsCard'
import ArtistCard from '../components/ArtistCard'
import Loader from '../components/Loader'
import ErrorPage from '../components/ErrorPage'

const Top = () => {

    const {data, error, loading} = useQuery(GET_DATA_QUERY)
  if(loading) return <Loader />;
  if(error) return <ErrorPage errorMessage={error.message}/>;

    return (
        <>
        <Hero />
        <div className="top-section">
          <h2 className="top-title">ARTISTS</h2>
          <div className="artist-list">
            {data.artistCollection.items.map(artist => (
                <ArtistCard key={artist.sys.id} artistName={artist.artistName} artistPhoto={artist.artistPhoto.url} />
              ))}
          </div>
        </div>
        <div className="top-section">
          <h2 className="top-title">RECENT UPDATES</h2>
          <div className="music-list">
            {data.newsCollection.items.map(news => (
                <NewsCard key={news.sys.id} newsId={news.sys.id} newsTitle={news.newsTitle} newsDate={news.newsDate} newsImage={news.newsThumbnail.url} />
              ))}
          </div>
        </div>
        </>
    )
}

export default Top

// import firebase from 'firebase/app';
// import 'firebase/firestore';

// const {REACT_APP_FB_API_KEY, REACT_APP_FB_AUTH_DOMAIN, REACT_APP_FB_PROJECT_ID, REACT_APP_FB_STORAGE_BUCKET, REACT_APP_FB_MESSAGING_SENDER_ID, REACT_APP_FB_APP_ID, REACT_APP_FB_MEASUREMENT_ID} = process.env;
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseApp  = firebase.initializeApp({
//     apiKey: REACT_APP_FB_API_KEY,
//     authDomain: REACT_APP_FB_AUTH_DOMAIN,
//     projectId: REACT_APP_FB_PROJECT_ID,
//     storageBucket: REACT_APP_FB_STORAGE_BUCKET,
//     messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
//     appId: REACT_APP_FB_APP_ID,
//     measurementId: REACT_APP_FB_MEASUREMENT_ID
//   });

//   export const db = firebaseApp.firestore()
//   export default firebaseApp

import firebase from 'firebase/app';
import 'firebase/firestore';

// const {REACT_APP_FB_API_KEY, REACT_APP_FB_AUTH_DOMAIN, REACT_APP_FB_PROJECT_ID, REACT_APP_FB_STORAGE_BUCKET, REACT_APP_FB_MESSAGING_SENDER_ID, REACT_APP_FB_APP_ID} = process.env;

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID
  };

  firebase.initializeApp(firebaseConfig);
  export default firebase;
import { useQuery } from '@apollo/client'
import { GET_DATA_QUERY } from '../graphql/Queries'
import MusicCard from './MusicCard'
import Loader from './Loader'
import ErrorPage from './ErrorPage'
import { useParams } from 'react-router-dom'

const ArtistPage = () => {
    let {artistId} = useParams();
    const ARTIST_NAME = artistId;
    const {loading, error, data} = useQuery(GET_DATA_QUERY, {
      variables: {artistName: `${ARTIST_NAME}`},
    })

    if(loading) return <Loader />;
    if(error) return <ErrorPage errorMessage={error.message}/>;
    const artistArray = [...data.artistCollection.items]
    const artistData = artistArray[0]
    const targetSnsLinks = artistData.snsLinks;

    const youtube_parser = (url) =>{
      var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
      var match = url.match(regExp);
      return (match && match[1].length===11)? match[1] : false;
    }

    
    return (
        <>
            <div className='artist-page-container'>
                  <h1 data-text={artistData.artistName}>{artistData.artistName}</h1>
              <div className="artist-sns-links">
                <ul>
                {(!targetSnsLinks) ? (console.log(`no sns data`)) : (
                  targetSnsLinks.map((link, index) => (
                    <li key={index}><a href={link} target='_blank' rel='noreferrer'>{
                      (link.includes('instagram')) ? (<i className="fab fa-instagram"></i>) :
                      (link.includes('twitter')) ? (<i className="fab fa-twitter"></i>) :
                      (link.includes('soundcloud')) ? (<i className="fab fa-soundcloud"></i>) :
                      (link.includes('youtube')) ? (<i className="fab fa-youtube"></i>) :
                      (link.includes('spotify')) ? (<i className="fab fa-spotify"></i>) :
                      (link.includes('facebook')) ? (<i className="fab fa-facebook"></i>) :
                      (link.includes('apple')) ? (<i className="fab fa-apple"></i>) :
                      (link.includes('tiktok')) ? (<i className="fab fa-tiktok"></i>) :
                      (<p>{link}</p>)}</a></li>
                  ))
                )}
                </ul>
              </div>
              
              {artistData.artistVideo ? (
              <div className="artist-video-wrapper my-6">
                <div className='artist-video'>
                  <iframe src={`https://www.youtube.com/embed/${youtube_parser(artistData.artistVideo)}`} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                </div>
              </div>
              ) : ''}
              <div className="music-list">
            {error && (<h1>{`Error Caught ${error.message}`}</h1>)}
            {loading ? (<h2>Loading Now</h2>) : (
            data.musicCollection.items.map(music => {
              if(music.musicArtistCollection.items.some(e => e.artistName === artistData.artistName)){
              return <MusicCard  key={music.sys.id} musicTitle={music.musicTitle} musicArtwork={music.musicArtwork.url} musicLink={music.musicLink} musicArtist={music.musicArtistCollection.items} />
              } else {
                return null
              }
              })
          )}
          </div>

              <div className="artist-profile">
                <div className="artist-photo">
                  <img src={artistData.artistPhoto.url} alt={artistData.artistPhoto.title} />
                </div>
                <div className="artist-bio">
                  <h3>{artistData.artistName}</h3>
                  <p>{artistData.artistBio}</p>
                </div>
              </div>
            </div>

        </>
    )
}

export default ArtistPage





import React, { useState } from 'react';
import firebase from '../firebase'

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [showOrderInput, setShowOrderInput] = useState(false);
    const [orderNo, setOrderNo] = useState(null);
    const [message, setMessage] = useState('');

    const [loader, setLoader] = useState(false);
    const [sent, setSent] = useState(false);
    const handleShowOrderInput = (e) => {
        if(e.target.value === 'return' || e.target.value ===  'deliverys'){
            setShowOrderInput(true);
        }else{
            setShowOrderInput(false);
        }
        setSubject(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        let time = new Date();

        firebase.firestore().collection('contacts').add({
            time:time,
            subject:subject,
            orderNo:orderNo,
            name:name,
            email:email,
            message:message,
        })
        .then(() => {
            setLoader(false);
        })
        .catch((error) => {
            alert((error.message));
            setLoader(false);
        });
        setName('');
        setEmail('');
        setSubject('');
        setOrderNo('');
        setMessage('');
        setSent(true);
    }

        
    return (
        <>
            <section className="page-header">
                <h1>CONTACT</h1>
                <h3>Get in touch with us.</h3>
            </section>

                <div className={sent ? 'thank-you' : 'disabled'}>
                    <h3>Thank you for reaching out to us.</h3>
                </div>
                <div className={sent ? 'disabled' : 'contact-wrapper px-1'}>
                    <div id='error'></div>
                    <form onSubmit={handleSubmit} className=''>
                        <label>お名前*</label>
                        <input type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} required />
                        <label>メールアドレス*</label>
                        <input type="email" placeholder="Your Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        <label for="subject">お問い合わせ件名</label>
                        <select name="subject" id="subject" onChange={handleShowOrderInput} required>
                            <option value="work">お仕事やブッキングなどに関するお問い合わせ</option>
                            <option value="return">SHOPお問い合わせ（返品・不良品について）</option>
                            <option value="deliverys">SHOPお問い合わせ（配送について）</option>
                            <option value="payment">SHOPお問い合わせ（支払いについて）</option>
                            <option value="shop">SHOPお問い合わせ</option>
                            <option value="other" selected>その他</option>
                        </select>
                        <label className={showOrderInput ? 'showInput' : 'disabled'}>注文番号</label>
                        <input type="text" placeholder="#XXXXXXXX" onChange={(e) => setOrderNo(e.target.value)} className={showOrderInput ? 'showInput' : 'disabled'}/>
                        <label>お問い合わせ内容*</label>
                        <textarea placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>
                        <button type='submit' className={loader ? 'loading-btn' : 'submit-btn'}>SUBMIT</button>
                    </form>
                </div>
        </>
    )
}

export default Contact

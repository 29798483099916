import React from 'react';
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import './Styles/App.scss';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactGA.initialize('UA-196848181-1');
const history = createBrowserHistory();
history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
});

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});


const {REACT_APP_SPACE_ID, REACT_APP_CDA_TOKEN} = process.env;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `https://graphql.contentful.com/content/v1/spaces/${REACT_APP_SPACE_ID}`,
  headers: {
    Authorization : `Bearer ${REACT_APP_CDA_TOKEN}`,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

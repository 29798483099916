import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Artists from './components/Artists'
import ArtistPage from './components/ArtistPage'
import Music from './components/Music'
import News from './components/News'
import NewsPost from './components/NewsPost'
import Contact from './components/Contact'
import NotFound from './NotFound'
import Shop from './components/Shop'
import Top from './components/Top'

function App() {

  const componentDidMount = () => { 
    const { pathname } = this.props.location;
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }

  
  return (
    <Router>
    <div className="container">
    <Navbar />
    <ScrollToTop />
      <Switch>
        <Route path='/' exact component={Top}/>
        <Route path='/artists' exact component={Artists}/>
        <Route path='/music' exact component={Music}/>
        <Route path='/news' exact component={News}/>
        <Route path='/shop' exact component={Shop}/>
        <Route path='/contact' exact component={Contact}/>
        <Route path='/news/:newsId' component={NewsPost}/>
        <Route path='/artists/:artistId' component={ArtistPage}/>
        <Route component={NotFound}/>
      </Switch>

      <Footer />
    </div>
    </Router>
  );
}

export default App;
 
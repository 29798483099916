import {gql} from '@apollo/client';

export const GET_DATA_QUERY = gql`
query Data($artistName:String, $newsId:String){
artistCollection(limit: 50, order: artistName_ASC, where: {artistName: $artistName}) {
    items {
      sys {
        id
      }
      artistName
      artistVideo
      artistBio
      snsLinks
      artistPhoto {
        title
        url(transform: {width: 500})
      }
      linkedFrom {
        musicCollection(limit: 20) {
          items {
            ...musicFields
          }
        }
      }
    }
  }
  newsCollection(limit: 50, order: newsDate_DESC, where: {sys: {id: $newsId}}) {
    items {
      sys {
        id
      }
      newsTitle
      newsThumbnail {
        url(transform: {width: 500})
      }
      newsDate
      newsContent {
        json
      }
    }
  }
  musicCollection(limit: 50, order: releaseDate_DESC) {
    items {
      ...musicFields
    }
  }
}

fragment musicFields on Music {
  sys {
    id
  }
  musicTitle
  musicArtwork {
    url(transform: {width: 300})
  }
  musicLink
  musicArtistCollection(limit:5){
    items{
      artistName
    }
  }
}
`
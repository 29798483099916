import { Link } from 'react-router-dom'

const MusicCard = ({ musicTitle, musicArtwork, musicLink, musicArtist }) => {
    return (
            <div className='music-col'>
                <a href={musicLink} target="_blank" rel="noreferrer"><img src={musicArtwork} alt="" />
                <p>{musicTitle}</p></a>
                {/* <p>{musicArtist}</p> */}
                {musicArtist.map((artist, index) => (
                    <Link to={`../artists/${artist.artistName}`} key={index}><p className='music-artist-name'>{artist.artistName}</p></Link>
                ))}
            </div>
    )
}


MusicCard.defaultProps = {
    musicTitle: 'No TItle' ,
    musicArtwork: 'https://jmva.or.jp/wp-content/uploads/2018/07/noimage.png'
}

export default MusicCard

import { useQuery } from '@apollo/client'
import { GET_DATA_QUERY } from '../graphql/Queries'
import ArtistCard from './ArtistCard'
import Loader from './Loader'
import ErrorPage from './ErrorPage'

const Artists = () => {

  const {data, error, loading} = useQuery(GET_DATA_QUERY)
  if(loading) return <Loader />;
  if(error) return <ErrorPage errorMessage={error.message}/>;

    return (
        <>
            <section className="page-header">
                <h1>ARTISTS</h1>
            </section>
            <div className="artist-list">
            {data.artistCollection.items.map(artist =>  (
              <ArtistCard  key={artist.sys.id} artistName={artist.artistName} artistPhoto={artist.artistPhoto.url} artistPhotoTitle={artist.artistPhoto.title} />
            ))}
          </div>
        </>
    )
}

export default Artists

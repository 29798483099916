import { useQuery } from '@apollo/client'
import { GET_DATA_QUERY } from '../graphql/Queries'
import { useParams } from 'react-router-dom'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer'
import {BLOCKS, INLINES, MARKS} from '@contentful/rich-text-types'
import Loader from './Loader'
import ErrorPage from './ErrorPage'

const RICHTEXT_OPTIONS = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>
    },
    [INLINES.HYPERLINK]: (node, children) => {
      return <a href={node.data.uri} target='_blank' rel="noreferrer">{children}</a>
    },
    [MARKS.BOLD]: (node, children) => {
      <span>{children}</span>
    }
  }
}

const NewsPost = () => {
  let {newsId} = useParams(); 
  const NEWS_ID = newsId;
  const {loading, error, data} = useQuery(GET_DATA_QUERY, {
    variables: {newsId: `${NEWS_ID}`},
  })

  if(loading) return <Loader />;
  if(error) return <ErrorPage errorMessage={error.message}/>;
  const newsPostArray = [...data.newsCollection.items]
  const newsPostData = newsPostArray[0]
  const date = new Date(newsPostData.newsDate).toLocaleDateString()
    
    return (
        <article className='py-10 px-2'>
            <>
            <h1 className='article-title'>{newsPostData.newsTitle}</h1>
            <p className='article-date'>{date}</p>
            <img src={newsPostData.newsThumbnail.url} alt="" className='my-3' />
            {documentToReactComponents(newsPostData.newsContent.json, RICHTEXT_OPTIONS)}
            </>
        </article>
    )
}

export default NewsPost

import { Link } from 'react-router-dom'

const ArtistCard = ({artistName, artistPhoto, artistPhotoTitle}) => {
    return (
        <>
            <Link to={`/artists/${artistName}`} className="artist-col m-1"><div><img src={artistPhoto} alt={artistPhotoTitle} /><h3>{artistName}</h3></div></Link>
        </>
    )
}

ArtistCard.defaultProps = {
    artistName: 'No Name',
    artistPhoto: 'https://jmva.or.jp/wp-content/uploads/2018/07/noimage.png'
}

export default ArtistCard

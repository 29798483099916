import React, { useState, useRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import headerLogo from '../assets/mnnf_logo_white_header.png'

const  Navbar = () => {
    const [toggle, stateToggle] = useState(false);
    const handleClose = () => {toggle ? handleToggle() : stateToggle(false)};

    const [navBar, setNavbar] = useState(false);
    const changeBg = () => {(window.scrollY >= 150) ? setNavbar(true) : setNavbar(false)}
    window.addEventListener('scroll', changeBg);


    const navLinks = useRef(null);
    const handleToggle = () => {
        const array = [...navLinks.current.children];
            array.forEach((link, index) => {
                if(toggle === false){
                link.style.animation = `navLinkFadeIn 0.8s ease forwards ${index / 10 + 0.1}s`;
                }else{
                    link.style.animation = ''
                }
            })
            stateToggle(!toggle);
    };

    return (
        <nav className={navBar ? 'navbar navbar-not-transparent' : 'navbar'}>
            <div className={toggle ? 'navbar-burger toggle' : 'navbar-burger'} onClick={handleToggle}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
            <Link to="/"><img src={headerLogo} className="nav-logo" alt='header-logo' /></Link>
            <ul className={toggle ? 'nav-links nav-active' : 'nav-links'} ref={navLinks}>
                <NavLink to="/" exact activeClassName="navbar__link--active" className="navbar__link" onClick={handleClose}><li>TOP</li></NavLink>
                <NavLink to="/artists" activeClassName="navbar__link--active" className="navbar__link" onClick={handleClose}><li>ARTISTS</li></NavLink>
                <NavLink to="/music" activeClassName="navbar__link--active" className="navbar__link" onClick={handleClose}><li>MUSIC</li></NavLink>
                <a href="https://www.youtube.com/channel/UCRo9Qmh7qC29PHuTEECephA" target="_blank" rel="noreferrer" className="navbar__link" onClick={handleClose}><li>VIDEOS</li></a>
                <NavLink to="/news" activeClassName="navbar__link--active" className="navbar__link" onClick={handleClose}><li>NEWS</li></NavLink>
                <a href="https://shop.mnnf.tokyo/" className="navbar__link" onClick={handleClose}><li>SHOP</li></a>
                {/* <NavLink to="/shop" activeClassName="navbar__link--active" className="navbar__link" onClick={handleClose}><li>SHOP</li></NavLink> */}
                <NavLink to="/contact" activeClassName="navbar__link--active" className="navbar__link" onClick={handleClose}><li>CONTACT</li></NavLink>
            </ul>
        </nav>
    )
}

Navbar.defaultProps = {
    title: 'Task Tracker',
}


export default Navbar
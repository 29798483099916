import React from 'react'

const ErrorPage = ({ errorMessage }) => {
    return (
        <div className="error-wrapper">
            <h1 data-text="ERROR">ERROR</h1>
            <p>{errorMessage}</p>
        </div>
    )
}

export default ErrorPage

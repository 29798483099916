import { Link } from 'react-router-dom'
import footerLogo from '../assets/mnnf_logo_white_footer.png'

const Footer = () => {
    return (
        <footer>
            <div className="footer-sns-icons">
                <h1>FOLLOW MNNF</h1>
                <ul>
                    <li><a href="https://www.youtube.com/channel/UCRo9Qmh7qC29PHuTEECephA" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a></li>
                    <li><a href="https://open.spotify.com/user/vs1rjl7xbv0yk6g8hbv74ydl5" target="_blank" rel="noreferrer"><i className="fab fa-spotify"></i></a></li>
                    <li><a href="https://soundcloud.com/mnnfrcrds" target="_blank" rel="noreferrer"><i className="fab fa-soundcloud"></i></a></li>
                    <li><a href="https://www.instagram.com/mnnfrcrds/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="https://twitter.com/MnnfOfficial" target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></li>
                    {/* <li><a href="/" target="_blank" rel="noreferrer"><i className="fab fa-apple"></i></a></li> */}
                    <li><a href="https://www.tiktok.com/@mnnfofficial?" target="_blank" rel="noreferrer"><i className="fab fa-tiktok"></i></a></li>
                </ul>
            </div>
            <ul className="footer-nav-links">
                <Link to="/"><li>TOP</li></Link>
                <Link to="/artists"><li>ARTISTS</li></Link>
                <Link to="/music"><li>MUSIC</li></Link>
                <a href="https://www.youtube.com/channel/UCRo9Qmh7qC29PHuTEECephA" target="_blank" rel="noreferrer"><li>VIDEOS</li></a>
                <Link to="/news"><li>NEWS</li></Link>
                <a href="https://shop.mnnf.tokyo/"><li>SHOP</li></a>
                {/* <Link to="/shop"><li>SHOP</li></Link> */}
                <Link to="/contact"><li>CONTACT</li></Link>
            </ul>
            <img src={footerLogo} alt="" className="footer-logo" />
            <p className="copyright">Copyright © 2021 MNF, Inc. All rights reserved.</p>
        </footer>
    )
}

export default Footer
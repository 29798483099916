import { useQuery } from '@apollo/client'
import { GET_DATA_QUERY } from '../graphql/Queries'
import NewsCard from './NewsCard'
import Loader from './Loader'
import ErrorPage from './ErrorPage'

const News = () => {
    const {data, error, loading} = useQuery(GET_DATA_QUERY)
    if(loading) return <Loader />;
    if(error) return <ErrorPage errorMessage={error.message}/>;
    return (
        <>
            <section className="page-header">
                <h1>NEWS</h1>
            </section>
            <div className="news-list">
            {data.newsCollection.items.map(news =>  (
                <NewsCard key={news.sys.id} newsId={news.sys.id} newsTitle={news.newsTitle} newsDate={news.newsDate} newsImage={news.newsThumbnail.url} />
            ))}
            </div>
        </>
    )
}

export default News

import { useQuery } from '@apollo/client'
import { GET_DATA_QUERY } from '../graphql/Queries'
import MusicCard from './MusicCard'
import Loader from './Loader'
import ErrorPage from './ErrorPage'

const Music = () => {
    const {data, error, loading} = useQuery(GET_DATA_QUERY);
    if(loading) return <Loader />;
    if(error) return <ErrorPage errorMessage={error.message}/>;
    return (
        <>
            <section className="page-header">
                <h1>MUSIC</h1>
            </section>
            <div className="music-list">
            {data.musicCollection.items.map(music =>  (
              <MusicCard  key={music.sys.id} musicTitle={music.musicTitle} musicArtwork={music.musicArtwork.url} musicLink={music.musicLink} musicArtist={music.musicArtistCollection.items} />              
              // {console.log(music.musicArtistCollection.items)}
              ))}
          </div>
        </>
    )
}

export default Music

import React, { useEffect, useState } from "react";

const Shop = () => {
    const countdown = () => {
        let countDate = new Date('Fri, July 30, 2021 20:00:00 +0900').getTime();
        let now = new Date().getTime();
        let gap = countDate - now;
        let second = 1000;
        let minute = second * 60;
        let hour = minute * 60;
        let day = hour * 24;

        let timeLeft = {};
        if (gap > 0) {
            timeLeft = {
                days: Math.floor(gap / day),
                hours: Math.floor((gap % day) / hour),
                minutes: Math.floor((gap % hour) / minute),
                seconds: Math.floor((gap % minute) / second)
            }
            return timeLeft;
        }
    };
    const [timeLeft, setTimeLeft] = useState(countdown());
    useEffect(() => {
        const timer = setTimeout(() => {
          setTimeLeft(countdown());
        }, 1000);
        return() => clearTimeout(timer);
      });

      const timerComponents = [];
      Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
          return;
        }
        timerComponents.push(
          <h3 className="countdown-components">
            {timeLeft[interval]} <span className="countdown-title">{interval}{" "}</span>
          </h3>
        );
      });


    return (
        <section className="shop-wrapper">
            <h1>MNNF WEB SHOP</h1>
            <div className="countdown">
              <a href="https://shop.mnnf.tokyo" className="btn01">ENTER</a>
            </div>
        </section>
    )
}

export default Shop
